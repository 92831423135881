export const isBrowser = typeof window !== "undefined";

export const isMobile =
  isBrowser &&
  (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
    window.navigator.userAgent,
  ) ||
    (window.navigator.userAgent.includes("Mac") &&
      "ontouchend" in window.document));

export const isChrome = isBrowser && /Chrome/i.test(window.navigator.userAgent);

export const hasShareApi = isBrowser && !!window.navigator.share;
