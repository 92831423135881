import styled from "styled-components";
import { Button, Text, type ThemeType } from "@cruk/cruk-react-components";

export const TopSectionWrapper = styled.div`
  @media print {
    display: none;
  }
`;

export const LogInButton = styled(Button)`
  padding-left: 1.5em;
  padding-right: 1.5rem;

  span div {
    margin-top: 0 !important;
  }
`;

export const UserNav = styled.nav<{
  $open: boolean;
  $isSmall: boolean;
  theme: ThemeType;
}>`
  position: fixed;
  width: 100%;
  z-index: 10000;
  background-color: ${({ theme }) => theme.colors.headerBackground};
  height: auto;
  overflow: hidden;
  transition:
    transform 0.2s ease,
    max-height 0.2s ease,
    opacity 0.2s ease;
  max-height: ${({ $open }) => ($open ? "1000px" : 0)};
  transform: ${({ $open }) => ($open ? "scaleY(1)" : "scaleY(0)")};
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  transform-origin: top right;
  border-bottom: solid 1px ${({ theme }) => theme.colors.headerBorder};
  visibility: ${({ $open }) => ($open ? "visible" : "hidden")};

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: auto;
    right: 1.5rem;
    top: ${({ $isSmall }) => ($isSmall ? "3.75rem" : "5.25rem")};
    padding: 0 ${({ theme }) => theme.spacing.m};
    border: solid 1px ${({ theme }) => theme.colors.headerBorder};
    transform: ${({ $open }) => ($open ? "scale(1)" : "scale(0)")};
  }

  /* we need to match header margin behaviour when absolutely positioned */
  @media (min-width: 1068px) {
    right: calc(50% - 510px);
  }
`;

export const LinkText = styled(Text)``;

export const UserNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const UserNavSpan = styled.span<{
  theme: ThemeType;
}>`
  position: relative;
  display: block;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xxs} 0;
  text-decoration: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  ${LinkText} {
    color: ${({ theme }) => theme.colors.textDark};
    font-weight: ${({ theme }) => theme.typography.fontWeightLinks};
    transition: color 0.2s ease;
  }

  &:hover {
    cursor: pointer;
    ${LinkText} {
      color: ${({ theme }) => theme.colors.linkColorHover};
    }
  }
`;

export const UserNavLink = styled.a<{
  theme: ThemeType;
}>`
  position: relative;
  display: block;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xxs} 0;
  text-decoration: none;
  border: none;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  ${LinkText} {
    color: ${({ theme }) => theme.colors.textDark};
    font-weight: ${({ theme }) => theme.typography.fontWeightLinks};
    transition: color 0.2s ease;
  }

  &:hover {
    cursor: pointer;
    ${LinkText} {
      color: ${({ theme }) => theme.colors.linkColorHover};
    }
  }
`;

export const FlippingIcon = styled.span<{
  theme: ThemeType;
  $active: boolean;
}>`
  transform: ${({ $active }: { $active: boolean }) =>
    $active ? "rotate(180deg) scaleX(-1)" : "scaleX(1)"};
  transition: transform linear 0.5s;
  margin: auto;
`;

export const AvatarButton = styled(Button)`
  position: relative;
  height: 48px;
  padding: 0 16px !important;

  span {
    display: inline-block;
    line-height: 1;
  }
`;

export const AvatarWrapper = styled.span<{
  theme: ThemeType;
}>`
  margin-right: ${({ theme }) => theme.spacing.xxs};

  img {
    vertical-align: bottom;
  }
`;

export const AccountPromptBadge = styled.div<{
  theme: ThemeType;
}>`
  position: absolute;
  top: ${({ theme }) => `-${theme.spacing.xxs}`};
  right: 0;
`;

export const SettingsPromptBadge = styled.div<{
  theme: ThemeType;
}>`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.xxs};
`;

export const LinkTextWithBadge = styled.div`
  display: flex;
  justify-content: center;

  p {
    margin-bottom: 0;
  }
`;
