import React, {
  useState,
  createContext,
  useContext,
  type ReactNode,
  useMemo,
  type Dispatch,
  type SetStateAction,
} from "react";
import {
  bowelbabeTheme,
  crukTheme,
  su2cTheme,
  type ThemeType,
  rflTheme,
} from "@cruk/cruk-react-components";

import { type BrandNameType } from "@fwa/src/types";
import { ThemeProvider } from "styled-components";

type BrandContextType = [
  BrandNameType,
  Dispatch<SetStateAction<BrandNameType>>,
];

const defaultState: BrandContextType = ["cruk", () => null];

export const BrandContext = createContext<BrandContextType>(defaultState);

type Props = {
  // Adding brandName as a prop to allow providers parent to
  // set theme as well as its children.
  initialBrandName: BrandNameType;
  children: ReactNode;
};

export const getTheme = (themeBrand: string): ThemeType => {
  if (themeBrand === "bowelbabe") {
    const theme: ThemeType = {
      ...bowelbabeTheme,
    };

    return theme;
  }

  if (themeBrand === "su2c") {
    const theme: ThemeType = {
      ...su2cTheme,
    };

    return theme;
  }

  if (themeBrand === "rfl") {
    const theme: ThemeType = {
      ...rflTheme,
    };

    return theme;
  }

  const theme: ThemeType = {
    ...crukTheme,
  };

  return theme;
};

const BrandContextProviderInner = ({ initialBrandName, children }: Props) => {
  const [brandName, setBrandName] = useState(initialBrandName);

  const value = useMemo(
    () => [brandName, setBrandName],
    [brandName],
  ) as BrandContextType;

  return (
    <BrandContext.Provider value={value}>
      <ThemeProvider theme={getTheme(brandName)}> {children}</ThemeProvider>
    </BrandContext.Provider>
  );
};

export const BrandContextProvider = ({ initialBrandName, children }: Props) => (
  <BrandContextProviderInner initialBrandName={initialBrandName}>
    {children}
  </BrandContextProviderInner>
);

export const useBrandContext = () => useContext(BrandContext);
