import {
  useRef,
  type DependencyList,
  type RefObject,
  type MutableRefObject,
} from "react";

import { useLayoutEffectBrowser } from "./useLayoutEffectBrowser";

const isBrowser = typeof window !== `undefined`;

type PositionType = {
  x: number;
  y: number;
};
type EffectProps = {
  prevPos: PositionType;
  currPos: PositionType;
};

function getScrollPosition({
  element,
  useWindow,
}: {
  element?: RefObject<HTMLElement> | MutableRefObject<undefined> | null;
  useWindow: boolean;
}) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target
    ? target.getBoundingClientRect()
    : { top: 0, left: 0 };

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export const useScrollPosition = (
  effect: (props: EffectProps) => void,
  deps: DependencyList,
  element: RefObject<HTMLElement> | MutableRefObject<undefined> | null,
  useWindow: boolean,
  wait: number,
) => {
  const scrollPosition = useRef(getScrollPosition({ useWindow, element }));

  let throttleTimeout: number | null = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: scrollPosition.current, currPos });
    scrollPosition.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffectBrowser(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = window.setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };
    if (isBrowser) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (isBrowser) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, deps);
};

export default useScrollPosition;
