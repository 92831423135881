import React, { useEffect, useState } from "react";
import { useRouter } from "next/compat/router";

import { LoadingBar } from "./styles";

export const PageLoadingIndicator = () => {
  const router = useRouter();
  const { events } = router || {};

  const [isVisible, setIsVisible] = useState(false);

  const finished = () => {
    setIsVisible(false);
  };

  const routeChangeStart = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    events?.on("routeChangeStart", routeChangeStart);
    events?.on("routeChangeComplete", finished);
    events?.on("routeChangeError", finished);
    return () => {
      events?.off("routeChangeStart", routeChangeStart);
      events?.off("routeChangeComplete", finished);
      events?.off("routeChangeError", finished);
    };
  }, []);

  return <LoadingBar $isVisible={isVisible} />;
};

export default PageLoadingIndicator;
