import { isBrowser } from "@fwa/src/utils/browserUtils";

/* eslint-disable  @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-assignment,
@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
export const sendGTMTrackingEvent = (data: object) => {
  if (!isBrowser) return;
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(data);
};
/* eslint-enable */

export default sendGTMTrackingEvent;
