import {
  fetcher,
  FWA_BASE_URL,
  FWS_BASE_URL,
} from "@fwa/src/services/apiClient";

import {
  type ImageType,
  type ImageUpdateProps,
  type InnerImageType,
} from "@fwa/src/types";

// unlike crop rotate isn't stored in our DB, it affects the image stored with AWS
// which is why it is a parmeter in the URL and not in the body of the request

export const updateImage = ({
  imageId,
  crop,
  rotate,
  title,
}: ImageUpdateProps): Promise<ImageType> =>
  fetcher(
    `${FWS_BASE_URL}/images/${imageId}${rotate ? `?rotate=${rotate}` : ""}`,
    {
      method: "PATCH",
      body: JSON.stringify({ crop, title }),
    },
  ).then((res) => res as ImageType);

export const deleteImage = ({
  imageId,
}: ImageUpdateProps): Promise<undefined> =>
  fetcher(`${FWS_BASE_URL}/images/${imageId}`, {
    method: "DELETE",
  }).then((res) => res as undefined);

// order of preference image.filePath -> image.url -> empty string
export const imagePathFromImageType = (
  image: ImageType | InnerImageType,
): string =>
  image?.filePath ? `/images/${image?.filePath}` : `${image?.url ?? ""}`;

export const imageAbsoluteUrlFromImageType = (
  image: ImageType | InnerImageType,
): string =>
  image?.filePath
    ? `${FWA_BASE_URL}/images/${image?.filePath}`
    : `${image?.url ?? ""}`;
