import { useEffect } from "react";
import { hotjarSetUserAttribute } from "@fwa/src/services/hotjar";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";

/**
 * HotjarListener set attributes on Hotjar user context based on fundraiser state
 */
export const HotjarListener = () => {
  const [fundraiserState] = useFundraiserContext();
  const { fundraiser } = fundraiserState;

  // if fundraiser updates then we update the user context
  useEffect(() => {
    if (fundraiserState.loggedInStatus === "loggedIn" && fundraiser) {
      hotjarSetUserAttribute({
        is_logged_in: true,
        fundraiser_id: fundraiser.uniqueId,
      });
    } else if (fundraiserState.loggedInStatus === "loggedOut") {
      hotjarSetUserAttribute({ is_logged_in: false, fundraiser_id: null });
    }
  }, [fundraiserState.loggedInStatus]);

  return null;
};

export default HotjarListener;
