import { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { type AppProps } from "next/app";
import { SWRConfig } from "swr";
import { StyleSheetManager } from "styled-components";
import { GlobalStyleNoFontFace, Fontface } from "@cruk/cruk-react-components";

// import { initHotjar } from "@fwa/src/services/hotjar";
import { setUpDataDog } from "@fwa/src/services/dataDog";
import { FundraiserContextProvider } from "@fwa/src/contexts/FundraiserContext";
import { BrandContextProvider } from "@fwa/src/contexts/BrandContext";
import { TrackingContextProvider } from "@fwa/src/contexts/TrackingContext";
import { ActiveSectionContextProvider } from "@fwa/src/contexts/ActiveSectionContext";
import { ModalContextProvider } from "@fwa/src/contexts/ModalContext";
import { getCache } from "@fwa/src/store/SwrCache";
import { onPerformanceCookieEnabled } from "@fwa/src/services/oneTrust";
import { initUserzoom } from "@fwa/src/services/userZoom";
import { OptimizelyContextProvider } from "@fwa/src/contexts/OptimizelyContext";

import { LoginWrapper } from "@fwa/src/components/LoginWrapper";
import { PageLoadingIndicator } from "@fwa/src/components/PageLoadingIndicator";
import { Layout } from "@fwa/src/components/Layout";
import { TrackingListener } from "@fwa/src/components/TrackingListener";
import { OptimizelyFundRaiserLoader } from "@fwa/src/components/OptimizelyFundraiserLoader";
import { HotjarListener } from "@fwa/src/components/HotjarListener";

import { type BrandNameType } from "@fwa/src/types";

interface PagePropsWithThemeType {
  themeName?: string;
}

let didInit = false;

const App = ({ Component, pageProps }: AppProps) => {
  const pagePropsWithTheme = pageProps as PagePropsWithThemeType;
  const brandName: BrandNameType = (
    pagePropsWithTheme.themeName ? pagePropsWithTheme.themeName : "cruk"
  ) as BrandNameType;

  const router = useRouter();
  const { pathname } = router || {};

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      setUpDataDog();
      // onPerformanceCookieEnabled([initHotjar]); //TODO enable once this is removed from GTM wrapper
      onPerformanceCookieEnabled([initUserzoom]);
    }
  }, []);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        provider: () => getCache(),
      }}
    >
      <FundraiserContextProvider>
        <BrandContextProvider initialBrandName={brandName}>
          <TrackingContextProvider>
            <OptimizelyContextProvider>
              <OptimizelyFundRaiserLoader />
              <HotjarListener />
              <ModalContextProvider>
                <ActiveSectionContextProvider>
                  <TrackingListener />
                  <GlobalStyleNoFontFace />
                  <Head>
                    <meta
                      name="viewport"
                      content="initial-scale=1.0, width=device-width, height=device-height"
                    />
                    {/* This is way smaller than creating a styled component global style in _app */}
                    <style>
                      {`
                      ${Fontface}
                      a {
                        text-decoration: none;
                        color: black;
                      }
                      html {
                          -webkit-scroll-padding-top: 4em;
                          -moz-scroll-padding-top: 4em;
                          -ms-scroll-padding-top: 4em;
                          scroll-padding-top: 4em;
                        }`}
                    </style>
                  </Head>
                  <LoginWrapper>
                    <StyleSheetManager enableVendorPrefixes>
                      <Layout path={pathname || ""}>
                        <Component {...pageProps} />
                      </Layout>
                    </StyleSheetManager>
                  </LoginWrapper>
                  <PageLoadingIndicator />
                </ActiveSectionContextProvider>
              </ModalContextProvider>
            </OptimizelyContextProvider>
          </TrackingContextProvider>
        </BrandContextProvider>
      </FundraiserContextProvider>
    </SWRConfig>
  );
};

export default App;
