import { useEffect, useState } from "react";
import { useRouter } from "next/compat/router";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { useBrandContext } from "@fwa/src/contexts/BrandContext";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";

const routeToGTMPageType: Record<string, string> = {
  "/donate/[slug]/confirmation": "donation_confirmation",
  "/donate/[slug]/details": "donation_donation_details",
  "/fundraiser/dashboard": "dashboard",
  "/fundraiser/settings": "account_settings",
  "/page/[...slug]": "fundraiser_page",
  "/partner/[slug]": "partner_page",
  "/payment/failed": "payment_failed",
  "/team/[slug]": "team_page",
  "/team/[slug]/join": "team_page_join",
  "/team/[slug]/leave": "team_page_leave",
  "/team/[slug]/members": "team_page_members",
  "/team/[slug]/members/edit": "team_page_members_edit",
  "/unite/[slug]": "superteam_page",
  "/unite/[slug]/join": "superteam_page_join",
  "/unite/[slug]/pages": "superteam_page_fundraising_pages",
  "/unite/[slug]/teams": "superteam_page_team_pages",
  "/createpage": "create_page",
  "/signup": "signup",
  "/team-create": "team_create",
  "/team-search": "team_search",
  "/teamsignup": "team_signup",
  "/": "homepage",
};

/** TrackingListener component listens to all the contexts
 * and collates all the information about router, logged in user, current page details
 * so that all this information can be sent to the external tracking and error logging service */
export const TrackingListener = () => {
  const [hasPageChanged, setHasPageChanged] = useState(true);
  const router = useRouter();
  const { route } = router || {};
  const [brandContext] = useBrandContext();
  const [fundraiserState] = useFundraiserContext();
  const { trackingContext, setTrackingContext, sendTrackingEvent } =
    useTrackingContext();

  /**
   * update tracking context when ever route, brandContext or fundraiserState changes
   */
  const updateContext = () => {
    const oldGTMPageType = trackingContext.pageType;
    const newGTMPageType = route ? routeToGTMPageType[route] : "unknown";
    const isPageChanged = oldGTMPageType !== newGTMPageType;
    const newUserId = fundraiserState?.fundraiser?.uniqueId || null;
    const newBrandName = brandContext as string;

    const newPageLoadData = isPageChanged
      ? { pageType: newGTMPageType, event: "page_loaded" }
      : {};

    const newData = {
      ...trackingContext,
      ...newPageLoadData,
      brand: newBrandName,
      loggedInUserId: newUserId,
    };
    setTrackingContext(newData);

    // set a flag to indicate the route change change so that after render we can do a page view event if needed
    if (isPageChanged) {
      setHasPageChanged(true);
    }
  };

  /**
   * fire page view event if flag is set when page changes
   * but wait until logged in state is known incase we need to seed logged in use data
   */
  const shouldSendPageLoadedEvent = () => {
    const isLoggedInStatusUnknown =
      fundraiserState.loggedInStatus === "unknown";

    if (hasPageChanged && !isLoggedInStatusUnknown) {
      setHasPageChanged(false);
      sendTrackingEvent({
        loggedInUserId: fundraiserState?.fundraiser?.uniqueId || null,
      });
    }
  };

  useEffect(() => {
    updateContext();
  }, [route, brandContext, fundraiserState]);

  useEffect(() => {
    shouldSendPageLoadedEvent();
  }, [hasPageChanged, fundraiserState, trackingContext]);

  return null;
};

export default TrackingListener;
