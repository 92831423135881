import React, {
  useState,
  useContext,
  type Dispatch,
  type SetStateAction,
  type ReactNode,
  useMemo,
} from "react";

import { type PageActiveSectionType } from "@fwa/src/types";

export type ActiveSectionContextStateType = {
  activeSection: PageActiveSectionType;
};

const defaultState: ActiveSectionContextStateType = {
  activeSection: null,
};

export type ActiveSectionContextProviderType = [
  ActiveSectionContextStateType,
  Dispatch<SetStateAction<ActiveSectionContextStateType>>,
];

export const ActiveSectionContext =
  React.createContext<ActiveSectionContextProviderType>([
    { ...defaultState },
    () => {
      // placeholder
    },
  ]);

export const useActiveSectionContext = () => useContext(ActiveSectionContext);

type Props = {
  children: ReactNode;
};

export const ActiveSectionContextProvider = ({ children }: Props) => {
  const [state, setState] = useState({
    ...defaultState,
  });

  const value = useMemo(
    () => [state, setState],
    [state],
  ) as ActiveSectionContextProviderType;

  return (
    <ActiveSectionContext.Provider value={value}>
      {children}
    </ActiveSectionContext.Provider>
  );
};
