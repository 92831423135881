import { useState, useEffect } from "react";
import { isBrowser } from "@fwa/src/utils/browserUtils";

/**
 *
 * @param key name of local storage key
 * @param defaultValue the value to be set in local storage when ready, and value returned if local storgage not ready
 * @param windowNotReadyValue the value to be returned before local storage ready if different from default value
 * @returns
 */
const getStorageValue = (
  key: string,
  defaultValue: string,
  windowNotReadyValue?: string,
): string | null => {
  // getting stored value
  if (!isBrowser) return windowNotReadyValue || defaultValue;
  const saved: string | null = localStorage.getItem(key);
  const initial = saved ? (JSON.parse(saved) as string) : null;
  return initial || defaultValue;
};

export const useLocalStorage = (
  key: string,
  defaultValue: string,
  windowNotReadyValue?: string,
) => {
  const [value, setValue] = useState(() =>
    getStorageValue(key, defaultValue, windowNotReadyValue),
  );

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return { value, setValue };
};

export default useLocalStorage;
