import { useEffect } from "react";
import optimizely from "@optimizely/optimizely-sdk";

import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";

import { OptimizelyLoader } from "@fwa/src/components/OptimizelyLoader";

const OPTIMIZELY_SDK_KEY = process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY || "";
const ENV_NAME = process.env.NEXT_PUBLIC_ENV_NAME || "";

/**
 * OptimizelyFundraiserLoader set attributes on optimizely user context based on fundraiser state
 */
export const OptimizelyFundRaiserLoader = () => {
  const { trackError } = useTrackingContext();
  const [fundraiserState] = useFundraiserContext();
  const { fundraiser } = fundraiserState;
  const { optimizelyContext, setOptimizelyUserAttributes } =
    useOptimizelyContext();

  if (ENV_NAME === "production") {
    optimizely.setLogger(null);
  }
  // if fundraiser updates then we update the user context
  useEffect(() => {
    if (
      !optimizelyContext.optimizelyClient ||
      !optimizelyContext.optimizelyUserContext
    )
      return;

    if (fundraiserState.loggedInStatus === "loggedIn" && fundraiser) {
      setOptimizelyUserAttributes({
        is_logged_in: true,
        fundraiser_id: fundraiser.uniqueId,
      });
    } else if (fundraiserState.loggedInStatus === "loggedOut") {
      setOptimizelyUserAttributes({ is_logged_in: false, fundraiser_id: null });
    }
  }, [fundraiserState.loggedInStatus, optimizelyContext.optimizelyUserContext]);

  return (
    <OptimizelyLoader
      sdkKey={OPTIMIZELY_SDK_KEY}
      onError={(err: Error) => {
        trackError(err, {
          component: "OptimizelyLoader",
          function: "setOptimizelyContext",
        });
      }}
    />
  );
};

export default OptimizelyFundRaiserLoader;
