import React, {
  useState,
  useContext,
  type Dispatch,
  type SetStateAction,
  type ReactNode,
  useMemo,
} from "react";

import { type FundraiserType } from "@fwa/src/types";

export type FundraiserContextStateType = {
  fundraiser: FundraiserType | null | undefined;
  // we don't have to record loggedInStatus
  // but it might be useful to know what is going on for potential loading states
  loggedInStatus: "unknown" | "loggedOut" | "loggedIn";
  tempPassword: string | null;
};

const defaultState: FundraiserContextStateType = {
  fundraiser: undefined,
  loggedInStatus: "unknown",
  tempPassword: null,
};

export type FundraiserContextProviderType = [
  FundraiserContextStateType,
  Dispatch<SetStateAction<FundraiserContextStateType>>,
];

export const FundraiserContext =
  React.createContext<FundraiserContextProviderType>([
    { ...defaultState },
    () => {
      // placeholder
    },
  ]);

export const useFundraiserContext = () => useContext(FundraiserContext);

type Props = {
  children: ReactNode;
};

export const FundraiserContextProvider = ({ children }: Props) => {
  const [state, setState] = useState({
    ...defaultState,
  });

  const value = useMemo(
    () => [state, setState],
    [state],
  ) as FundraiserContextProviderType;

  return (
    <FundraiserContext.Provider value={value}>
      {children}
    </FundraiserContext.Provider>
  );
};
