import { useCallback, useState } from "react";
import { useLayoutEffectBrowser } from "@fwa/src/hooks/useLayoutEffectBrowser";
import { isBrowser } from "../utils/browserUtils";

export const useOrientation = () => {
  const [orientation, setOrientation] = useState({
    angle: 0,
    type: "landscape-primary",
  });

  const handleOrientationchange = useCallback(() => {
    if (!isBrowser) return;
    setOrientation({
      type: "UNKNOWN",
      angle: window?.orientation || 0,
    });
  }, []);

  const handleChange = useCallback(() => {
    if (!isBrowser) return;
    const { angle, type } = window.screen.orientation;
    setOrientation({
      angle,
      type,
    });
  }, []);

  useLayoutEffectBrowser(() => {
    if (isBrowser) {
      if (window.screen?.orientation) {
        handleChange();
        window.screen.orientation.addEventListener("change", handleChange);
      } else {
        handleOrientationchange();
        window.addEventListener("orientationchange", handleOrientationchange);
      }
    }
    return () => {
      if (isBrowser) {
        if (window.screen?.orientation) {
          window.screen.orientation.removeEventListener("change", handleChange);
        } else {
          window.removeEventListener(
            "orientationchange",
            handleOrientationchange,
          );
        }
      }
    };
  }, []);

  return orientation;
};

export default useOrientation;
