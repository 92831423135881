import React, {
  useState,
  useContext,
  type Dispatch,
  type SetStateAction,
  type ReactNode,
} from "react";

type ModalContextStateType = ReactNode | undefined;

export type ModalContextProviderType = [
  ModalContextStateType,
  Dispatch<SetStateAction<ModalContextStateType>>,
];

export const ModalContext = React.createContext<ModalContextProviderType>([
  null,
  () => {
    // placeholder
  },
]);

export const useModalContext = () => useContext(ModalContext);

type Props = {
  children: ReactNode;
};

export const ModalContextProvider = ({ children }: Props) => {
  const value = useState<ModalContextStateType>(null);
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
