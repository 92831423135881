import React, {
  useState,
  useContext,
  type Dispatch,
  type SetStateAction,
  type ReactNode,
  useMemo,
} from "react";
import { trackDataDogError } from "@fwa/src/services/dataDog";
// import { trackCloudwatchError } from "@fwa/src/services/cloudWatchRum";

import { sendGTMTrackingEvent } from "@fwa/src/services/gtm";

export interface TrackingContextStateInterface {
  pageType: null | string;
  loggedInUserId: null | string;
  brand: null | string;
}

const defaultState: TrackingContextStateInterface = {
  pageType: null,
  loggedInUserId: null,
  brand: null,
};

export type TrackingContextProviderType = [
  TrackingContextStateInterface,
  Dispatch<SetStateAction<TrackingContextStateInterface>>,
];

export const TrackingContext = React.createContext<TrackingContextProviderType>(
  [
    { ...defaultState },
    () => {
      // placeholder
    },
  ],
);

const UNTRACKED_ERRORS = ["URL not unique"];

export const useTrackingContext = () => {
  const [trackingContext, setTrackingContext] = useContext(TrackingContext);

  const sendTrackingEvent = (eventData?: object) => {
    const newEventData = { ...trackingContext, ...eventData };
    sendGTMTrackingEvent(newEventData);
  };

  const trackError = (err: Error, extraData?: object) => {
    if (err.message && UNTRACKED_ERRORS.includes(err.message)) return;

    const newEventData = { ...extraData, ...trackingContext };
    trackDataDogError(err, newEventData);
  };

  return { trackingContext, setTrackingContext, sendTrackingEvent, trackError };
};

export const TrackingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, setState] = useState({
    ...defaultState,
  });

  const value = useMemo(
    () => [state, setState],
    [state],
  ) as TrackingContextProviderType;

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
};
