import { useState, useRef, useEffect } from "react";
import NextLink from "next/link";
import { useRouter } from "next/compat/router";
import {
  Header,
  Avatar,
  Text,
  Badge,
  IconFa,
} from "@cruk/cruk-react-components";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { useBrandContext } from "@fwa/src/contexts/BrandContext";
import { useOrientation } from "@fwa/src/hooks/useOrientation";
import { useScrollPosition } from "@fwa/src/hooks/useScrollPosition";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { imagePathFromImageType } from "@fwa/src/services/imageService";
import { useKey } from "@fwa/src/hooks/useKey";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import { logIn, logOut } from "@fwa/src/services/auth";

import { RowAlignCenter } from "@fwa/src/components/styles";

import {
  UserNav,
  LinkText,
  UserNavList,
  UserNavLink,
  UserNavSpan,
  FlippingIcon,
  AvatarButton,
  AvatarWrapper,
  LogInButton,
  AccountPromptBadge,
  SettingsPromptBadge,
  LinkTextWithBadge,
  TopSectionWrapper,
} from "./styles";

const ENV_NAME = process.env.NEXT_PUBLIC_ENV_NAME || "";
const HEADER_SCROLL_THRESHOLD = 66;

export const TopSection = () => {
  const { sendTrackingEvent, trackError } = useTrackingContext();
  const [brandContext] = useBrandContext();
  const { type: orientationType } = useOrientation();

  const navRef = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLElement>(null);
  const router = useRouter();
  const [userNavOpen, setUserNavOpen] = useState(false);
  const [fundraiserState, setFundraiserState] = useFundraiserContext();
  const { fundraiser, tempPassword } = fundraiserState;
  const [isSmall, setIsSmall] = useState(false);
  const hasWindow = isBrowser;

  const shouldHeaderBeSticky = isBrowser
    ? ["portrait-primary", "portrait-secondary"].includes(orientationType) ||
      window?.innerHeight > 600
    : true;

  const loginButtonDisabled = fundraiserState?.loggedInStatus !== "loggedOut";

  const isAdmin = /@cancer.org.uk$/.test(fundraiser?.emailAddress || "");
  const adminUrl =
    ENV_NAME === "production"
      ? "https://admin.fundraise.cancerresearchuk.org/admin"
      : `https://integration.admin.ofr.int.cruk.org/`;

  const avatartUrl = fundraiser?.profileImage
    ? fundraiser.profileImage.entityType === "ImageFacebook"
      ? fundraiser.profileImage.url
      : imagePathFromImageType(fundraiser.profileImage)
    : "";

  useScrollPosition(
    ({
      currPos,
    }: {
      prevPos: { x: number; y: number };
      currPos: { x: number; y: number };
    }) => {
      const shouldShrink = isBrowser
        ? currPos.y > HEADER_SCROLL_THRESHOLD
        : false;
      if (shouldShrink !== isSmall) {
        setIsSmall(shouldShrink);
      }
    },
    [isSmall],
    null,
    true,
    100,
  );

  const handleAvatarClicked = () => {
    setUserNavOpen(!userNavOpen);
  };

  const handleLogOutClicked = () => {
    setUserNavOpen(false);
    sendTrackingEvent({
      event: "logout",
      loggedInUserId: null,
    });
    if (
      router?.pathname.startsWith("/fundraiser/") ||
      router?.pathname.startsWith("/signup/") ||
      router?.pathname.startsWith("/createpage/")
    ) {
      router?.push("/").catch((err) => {
        trackError(err as Error, { component: "TopSection" });
      });
    }
    logOut()
      .then(() => {
        setFundraiserState({
          ...fundraiserState,
          loggedInStatus: "loggedOut",
          fundraiser: null,
        });
        return undefined;
      })
      .catch((err) => {
        trackError(err as Error, { component: "TopSection" });
      });
  };

  const handleLinkClicked = () => {
    setUserNavOpen(false);
  };

  // outside click closes popover
  const clickShouldCloseMenu = (e: MouseEvent) => {
    const eventTarget = e.target as HTMLElement;
    // ignore button click let button clickhander work
    const isDescendantButton = buttonRef?.current?.contains(eventTarget);
    if (isDescendantButton) {
      return;
    }
    const isDescendantOfNav = navRef?.current?.contains(eventTarget);
    if (!isDescendantOfNav) {
      setUserNavOpen(false);
    }
  };

  // escape key closes popover
  const escShouldCloseMenu = () => {
    const focusedElement = document.activeElement;
    const isDescendantButton = buttonRef?.current?.contains(focusedElement);
    const isDescendantOfNav = navRef?.current?.contains(focusedElement);
    if (isDescendantButton || isDescendantOfNav) {
      setUserNavOpen(false);
      if (buttonRef?.current) buttonRef.current.focus();
    }
  };

  useKey(
    () => {
      escShouldCloseMenu();
    },
    {
      detectKeys: ["Escape"],
    },
    [],
  );

  useEffect(() => {
    if (hasWindow) {
      document.addEventListener("click", clickShouldCloseMenu, true);
    }
    return () => {
      if (hasWindow) {
        document.removeEventListener("click", clickShouldCloseMenu, true);
      }
    };
  }, [hasWindow]);

  return (
    <TopSectionWrapper data-component="top-section">
      <Header
        siteSlogan={
          brandContext === "cruk" ? "Together we are beating cancer" : undefined
        }
        isSticky={shouldHeaderBeSticky}
      >
        {fundraiser ? (
          <AvatarButton
            aria-expanded={userNavOpen}
            aria-controls="account-menu"
            ref={buttonRef}
            onClick={handleAvatarClicked}
            aria-label={`Account for ${fundraiser.forename} ${
              fundraiser.surname
            } ${userNavOpen ? "close" : "open"} menu`}
            appearance="secondary"
            data-cta-type="open-menu"
          >
            <RowAlignCenter>
              <AvatarWrapper>
                <Avatar
                  name={`${fundraiser.forename} ${fundraiser.surname}`}
                  url={avatartUrl}
                  size="s"
                />
              </AvatarWrapper>
              <FlippingIcon $active={userNavOpen}>
                <IconFa faIcon={faChevronDown} />
              </FlippingIcon>
            </RowAlignCenter>
            {tempPassword && (
              <AccountPromptBadge>
                <Badge size="xxs" backgroundColor="danger">
                  <Text textColor="white" textAlign="center">
                    1
                  </Text>
                </Badge>
              </AccountPromptBadge>
            )}
          </AvatarButton>
        ) : null}
        {!fundraiser ? (
          <LogInButton
            disabled={loginButtonDisabled}
            onClick={logIn}
            appearance="secondary"
          >
            Log in
          </LogInButton>
        ) : null}
      </Header>
      {fundraiser ? (
        <UserNav
          id="account-menu"
          ref={navRef}
          $isSmall={isSmall}
          $open={userNavOpen}
        >
          <UserNavList>
            <li>
              <NextLink
                href="/fundraiser/dashboard"
                onClick={handleLinkClicked}
                data-cta-type="link-dashboard"
              >
                <UserNavSpan>
                  <LinkText textAlign="center">Your dashboard</LinkText>
                </UserNavSpan>
              </NextLink>
            </li>
            <li>
              <NextLink
                href="/fundraiser/settings"
                onClick={handleLinkClicked}
                data-cta-type="link-account-settings"
              >
                <UserNavSpan>
                  <LinkTextWithBadge>
                    <LinkText textAlign="center">Account settings</LinkText>
                    {tempPassword && (
                      <SettingsPromptBadge>
                        <Badge size="xxs" backgroundColor="danger">
                          <Text textColor="white" textAlign="center">
                            1
                          </Text>
                        </Badge>
                      </SettingsPromptBadge>
                    )}
                  </LinkTextWithBadge>
                </UserNavSpan>
              </NextLink>
            </li>
            {isAdmin && (
              <li>
                <UserNavLink
                  target="_blank"
                  href={adminUrl}
                  data-cta-type="link-admin"
                >
                  <LinkText textAlign="center">Admin</LinkText>
                </UserNavLink>
              </li>
            )}
            <li>
              <UserNavLink
                href="https://www.cancerresearchuk.org/cancer-research-uk-giving-pages-help"
                data-cta-type="link-of-help-page"
              >
                <LinkText textAlign="center">Help</LinkText>
              </UserNavLink>
            </li>
            <li>
              <UserNavLink
                as="button"
                onClick={handleLogOutClicked}
                data-cta-type="log-out"
              >
                <LinkText textAlign="center">Log out</LinkText>
              </UserNavLink>
            </li>
          </UserNavList>
        </UserNav>
      ) : null}
    </TopSectionWrapper>
  );
};

export default TopSection;
