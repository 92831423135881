import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const LoadingBar = styled.div<{ theme: ThemeType; $isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  height: 4px;
  width: ${({ $isVisible }) => ($isVisible ? "100%" : "0px")};
  background-color: ${({ theme }) => theme.colors.secondary};
  transition: ${({ $isVisible }) => ($isVisible ? "width 8s" : "")};
  transition-delay: 0.2s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
`;

export default LoadingBar;
