import styled from "styled-components";
import { Button, type ThemeType } from "@cruk/cruk-react-components";

// makes sure footer stays near the bottom of the screen
export const PageContentWrapper = styled.div<{
  $isFullBleed: boolean;
  theme: ThemeType;
}>`
  min-height: calc(100vh - 200px);
  margin: 0;
  overflow: hidden;
  background-color: ${({ theme, $isFullBleed }) =>
    $isFullBleed ? theme.colors.backgroundLight : "transparent"};
  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin: 0
      ${({ theme, $isFullBleed }) => ($isFullBleed ? 0 : theme.spacing.xs)};
  }

  @media print {
    min-height: 100vh;
  }
`;

export const FooterWrapper = styled.div<{
  theme: ThemeType;
}>`
  padding-bottom: ${({ theme }) => theme.spacing.xxl};
  clear: both;
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  @media print {
    display: none;
  }
`;

export const StyledUL = styled.ul<{
  theme: ThemeType;
}>`
  padding-left: ${({ theme }) => theme.spacing.xxs};
  list-style: none;
`;

export const StyledButton = styled(Button)<{
  theme: ThemeType;
}>`
  && {
    border: none;
    text-transform: none;
    text-decoration: none;
    min-height: 0;
    font-family: ${({ theme }) => theme.typography.fontFamilyLinks};
    font-weight: ${({ theme }) => theme.typography.fontWeightLinks};
    font-size: ${({ theme }) => theme.fontSizes.s};
    color: ${({ theme }) => theme.colors.linkColor};
    position: relative;
    text-align: left;
  }
`;

export const StyledLi = styled.li<{
  theme: ThemeType;
}>`
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
  a {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const ChevronWrapper = styled.span<{
  $active: boolean;
  theme: ThemeType;
}>`
  margin: 0 ${({ theme }) => theme.spacing.xxs};
  transform: rotateX(${({ $active }) => ($active ? 180 : 0)}deg);
  transition: transform 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
export const TermsContent = styled.span`
  display: flex;
  white-space: pre;
`;
