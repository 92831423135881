/* eslint-disable no-console */
import { getToken, OAUTH_BASE_URL } from "@fwa/src/services/auth";
import { FWS_BASE_URL } from "@fwa/src/services/apiClient";
import { logger } from "@fwa/src/services/logger";

import { trackDataDogError } from "@fwa/src/services/dataDog";
import { type FundraiserType, type OauthUserType } from "@fwa/src/types";

export const getLoggedInFundraiser =
  (): Promise<FundraiserType | null | void> =>
    fetch(`${FWS_BASE_URL}/fundraisers/me`, {
      headers: {
        Accept: "application/json",
        "X-Authorization": `Bearer ${getToken() || ""}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res: Response) => {
        if (res.status === 404) return null;
        if (!res.ok) {
          throw new Error("get user data failed");
        }
        return res.json();
      })
      .then((fundraiser: FundraiserType) => {
        if (!fundraiser) return null;
        return getOauthDataForFundraiser(fundraiser);
      })
      .catch((err: Error) => {
        // user might not be logged in so we probably don't want to log this
        // trackDataDogError(err);
        logger.error("unable to get logged in fundraiser data", err);
        throw err;
      });

export const getOauthDataForFundraiser = async (
  fundraiser: FundraiserType,
): Promise<void | FundraiserType> =>
  fetch(`${OAUTH_BASE_URL}/api/users/me`, {
    headers: {
      Accept: "application/json",
      "X-Authorization": `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    },
    credentials: "include",
  })
    .then((res: Response) => res.json())
    .then((oauthData: OauthUserType) => {
      const fundraiserWithOauthUser: FundraiserType = {
        ...fundraiser,
        oauthUser: oauthData,
      };
      return fundraiserWithOauthUser;
    })
    .catch((err: Error) => {
      trackDataDogError(err, { function: "getOauthDataForFundraiser" });
      logger.error("Unable to get oauth data", err);
    });

export const createNewFundraiserUrl = `${FWS_BASE_URL}/fundraisers`;

export const fwsUrlFundraiser = ({ fundraiserId }: { fundraiserId: string }) =>
  `${FWS_BASE_URL}/fundraisers/${fundraiserId}`;

export const fwsUrlFundraiserProfileImage = ({
  fundraiserId,
}: {
  fundraiserId: string;
}) => `${FWS_BASE_URL}/fundraisers/${fundraiserId}/profileimages`;

export const fwsUrlFundraiserPublishedPages = ({
  fundraiserId,
  currentPage,
  itemsPerPage,
}: {
  fundraiserId: string;
  currentPage: number;
  itemsPerPage: number;
}): string =>
  `${FWS_BASE_URL}/fundraisers/${fundraiserId}/fundraisingpages/public?offset=${
    Math.max(currentPage - 1, 0) * itemsPerPage
  }&limit=${itemsPerPage}&filter[0][filterBy]=pageStatus.pageStatusCode&filter[0][filterValue]=unpublished&filter[0][filterMatch]=NOT LIKE&filter[0][filterLike]=exact`;

export const fwsUrlFundraiserTeamMemberships = ({
  fundraiserId,
}: {
  fundraiserId: string;
}): string =>
  `${FWS_BASE_URL}/fundraisers/${fundraiserId}/teammembers?limit=50`;

export const fwsUrlFundraiserFundraisingPagesForEvent = ({
  fundraiserId,
  eventCode,
}: {
  fundraiserId: string;
  eventCode: string;
}): string =>
  `${FWS_BASE_URL}/fundraisers/${fundraiserId}/fundraisingpages?filter[0][filterBy]=pageStatus.pageStatusCode&filter[0][filterValue]=published&filter[0][filterLike]=exact&filter[0][filterMatch]=%3D&filter[1][filterBy]=eventCode&filter[1][filterValue]=${eventCode}&limit=50`;

export const fwsUrlFundraiserMembershipsForPage = ({
  fundraiserId,
  teamId,
}: {
  fundraiserId: string;
  teamId: string;
}): string =>
  `${FWS_BASE_URL}/fundraisers/${fundraiserId}/pages/${teamId}/teammembers?membershipStatus[0]=member&membershipStatus[1]=leader&offset=0&limit=50`;

export const fwsUrlFundraiserFundraisingPagesAvailableForNewTeam = ({
  fundraiserId,
}: {
  fundraiserId: string;
}): string =>
  `${FWS_BASE_URL}/fundraisers/${fundraiserId}/fundraisingpages?filter[0][filterBy]=pageStatus.pageStatusCode&filter[0][filterValue]=published&filter[0][filterLike]=exact&filter[0][filterMatch]==&filter[1][filterBy]=joinToTeam&filter[1][filterValue]=all&filter[1][filterLike]=exact&offset=0&limit=50`;

export const fwsUrlFundraiserPagesAvaliableForExistingTeam = ({
  fundraiserId,
  teamId,
  isSuperTeam,
}: {
  fundraiserId: string;
  teamId: string;
  isSuperTeam: boolean;
}): string =>
  `${FWS_BASE_URL}/fundraisers/${fundraiserId}/fundraisingpages?filter[0][filterBy]=pageStatus.pageStatusCode&filter[0][filterLike]=exact&filter[0][filterMatch]==&filter[0][filterValue]=published&filter[1][filterBy]=${
    isSuperTeam ? "joinToSuperTeam" : "joinToTeam"
  }&filter[1][filterLike]=exact&filter[1][filterValue]=${teamId}&offset=0&limit=50`;

export const fwsUrlFundraiserGetFirstOpenFundraisingPage = ({
  fundraiserId,
}: {
  fundraiserId: string;
}): string =>
  `${FWS_BASE_URL}/fundraisers/${fundraiserId}/fundraisingpages/public?offset=0&limit=1&filter[0][filterBy]=pageStatus.pageStatusCode&filter[0][filterValue]=published&filter[0][filterMatch]=%3D&filter[0][filterLike]=exact`;
/* eslint-enable */
